/**
 *	Colours
 */

//navy
$primary: #20419a;
//gold
$secondary: #b4975a;
//dark brown
$third: #312624;

$menu-bg: #f4f4f4;

$off-white: #fdfdf6;
$grey-light: #f1ece4;
$grey-med: #7b7b7b;
$grey-dark: #313131;

$pColor: $grey-dark;
$hColor: $third;

$skew: -10deg;

$error: #f93535;

/**
 *	Typography	
 */


$pFont: proxima-nova, sans-serif;
$hFont: oswald, sans-serif;

/**
 *	Header Heights
 */

$header-desktop-height: 122px;
$header-mobile-height: 100px;

$utility-bar-desktop-height: 57px;

/**
 *	Other
 */

$transition: all 0.3s ease;