
.btn, .wrap .btn {
	display: inline-block;
	position: relative;
	text-decoration: none;
	text-align: center;
	transition: $transition;
	border:none;
	outline:none;
	cursor: pointer;
	font-size: 16px;
	overflow:hidden;
	@include hFont;
	letter-spacing: 5px;
	padding: 18px 30px;
	min-width: 200px;
	background-color: $primary;
	color:#fff;
	span {
		display: block;
		vertical-align: middle;
	}
	&.btn-stripped {
		background-color: transparent;
		color:$primary;
		min-width:100px;
		padding: 10px 20px 10px 0;

		&:after {
			@include iconFont;
			content:"\E008";
			position: absolute;
			right:-7px;
			top:50%;
			transform:translateY(-50%);
			font-size:26px;
			transition: 0s;
			display: block;
			width:auto;
			padding:0;
			line-height: 10px;
			transition: right 0.2s;
		}

		&:hover {
			color:$secondary;
			background-color: transparent;

			&:after {
				right:-9px;
			}
		}

		&.btn-stripped-gold {
			color:$secondary;

			&:hover {
				color:$primary;
			}
		}

		&.btn-stripped-white {
			color:#fff;

			&:hover {
				color:$secondary;
			}
		}		
	}
	&.white {
		background-color: #fff;
		color:$primary;
		&:hover {
			color:#fff;
		}
	}
	&:hover, &:focus {
		color:#fff;
		background-color: $secondary;
	}

}