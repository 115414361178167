section.block.styled-content {
	padding:8vw 0;
	margin:0;

	.bg-wrap {
		background-color: rgba($third,0.7);
		padding:5vw 0;
	}

	.title-container {
		max-width: 700px;
		margin:0 auto 30px;

		h2 {color:#fff; text-align: center; margin:0;}
	}

	.content {
		color:#fff;

		*:first-child {margin-top:0;}

		h4 {color:$secondary;}

		h1,h2,h6 {color:#fff;}
	}
	
	@include media-breakpoint-up(sm) {
	
	}
	@include media-breakpoint-up(md) {
		.title-container {
			margin-bottom:40px;
		}
	}
	@include media-breakpoint-up(lg) {
	
	}
	@include media-breakpoint-up(xl) {
	
	}

}

/* end of .styled-content */