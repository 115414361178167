.columned-content {
	padding: 6vw 0;
	position: relative;

	&.light-grey-bg {
		&:before, &:after {
			content:"";
			position: absolute;
			left:0; 
			right:0;
			height:20px;
			background-color: $grey-light;
			z-index: 20;
		}

		&:before {

			bottom:100%;
		}

		&:after {
			top:100%;
		}		

	}

	.btn {
		margin-top: 10px;
		margin-bottom:40px;
	}

	.content {
		p {
			&:first-child {
				margin-top: 0;
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		padding: 2vw 0;

		.btn {
			margin-bottom:0;
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
