.post-sorting {

	.select-container {
		position:relative;
		margin-bottom:30px;
		&:before {
			@include iconFont;
			content: "\";
			position: absolute;
			top:50%;
			transform:translateY(-50%);
			right:20px;
		}
		select {
			appearance:none;
			background-color: transparent;
			border:1px solid $grey-med;
		    width: 100%;
		    height: 45px;
		    padding: 0 20px;
		    font-family: $pFont;
		    position:relative;
		    z-index:1;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}