.home-banner, .interior-banner {
	padding: 12vw 0;
	position: relative;
	overflow: hidden;
	position: relative;
	z-index: 1;


	.img-cover,.img-cover-top, &:before {
		position: absolute;
		left:0;
		right:0;
		top:0;
		bottom: 0;
	}

	.img-cover {
		z-index: 5;
		// mix-blend-mode: multiply;
	}

	.container {
		z-index: 10;
		position: relative;
	}


	&:before {
		content:"";
		z-index: 7;
		background-color: rgba($primary,0.8);
		// mix-blend-mode: multiply;
	}


	.content {
		position: relative;
		color: #fff;
		max-width: 500px;
		h1 {
			color:#fff;
			margin:0;
		}
		h3 {margin:15px 0 0;}

		p {
			margin:15px 0 0;
		}
		.btn.btn-stripped {
			padding-left:0;
			margin-top: 20px;

			&:hover {color:#fff;}

		}

	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {

	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {


		.content {
			h1 {
				font-size: 44px;

			}

		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		&:before {
			width:65%;
			left:-10%;
			transform: skew($skew);
		}
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

		.content {
			h1 {
				font-size: 52px;

			}

		}
	}
}

&.interior-banner {
	

	@include media-breakpoint-up(md) {


		padding:6vw 0;
	}
}
