.gform_wrapper {

	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]), select, textarea {
		font-size: 12px;
		margin-top:3px;
		&::placeholder {
			font-size: 12px;
			color: rgba(151,151,151,1);
			font-weight: 100;
		}
	}

	input:not([type=radio]):not([type=checkbox]):not([type=submit]):not([type=button]):not([type=image]):not([type=file]){
		border:1px solid $secondary;
		border-radius: 0;
		height: 40px;
		color: #212121;
		padding:0px 10px!important;
		transition: $transition;
		&:hover {
			border:1px solid $primary;
		}
		&:focus{
			outline:none;
			background-color: #fff;
			border:1px solid $primary;
		}
	}

	.ginput_container {
		margin-top:3px;
	}

	.gfield_checkbox, .gfield_radio {
		label {
			top:3px;
			position: relative;
		}
	}

	.ginput_container_select {
		position: relative;
		padding:0;
		&:before {
			content:'';
			position: absolute;
			right: 2px;
			top: 50%;
			transform: translateY(-50%);
			@include iconFont;
			content: "\E010";
			color: $primary;
			font-size: 30px;
			z-index: 11;
		}
		select {
			font-size: 12px;
			font-weight: 100;
			position: relative;
			appearance:none;
			height: 40px;
			color: #212121;
			padding:0px 10px!important;
			z-index: 2;
			width:100%!important;
			border:1px solid $secondary;
			transition: $transition;
			border-radius: 0;
			position: relative;
    		z-index: 12;
    		background-color: transparent;

			option:selection {
				color: rgba(151,151,151,1);
			}
			&::-ms-expand {
				display: none;
			}
			&:hover {
				border:1px solid $primary;
			}
			&:focus{
				outline:none;
				background-color: transparent;
				border:1px solid $primary;
			}
		}
	}

	.top_label .gfield_label {
		margin-top: 5px;
		font-family: $pFont;
		font-weight: bold;
		font-size: 16px;
	}

	textarea {
		border:1px solid $secondary;
		padding:10px!important;
		transition: $transition;
		border-radius: 0;
		&:focus {
			outline:none;
			background-color: #fff;
			border:1px solid $primary;
		}
		&:hover, {
			border:1px solid $primary;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}