.image-callout {

	background-color: $grey-light;
	position: relative;

	&.light {

		&:before, &:after {
			content:"";
			position: absolute;
			left:0; 
			right:0;
			height:20px;
			background-color: $grey-light;
			z-index: 20;
		}

		&:before {
			bottom:100%;
		}

		&:after {
			top:100%;
		}		

	}



	.container {
		overflow: hidden;
	}

	.title-container {
		text-align: center;
		.title {
			margin-top: 0;
	
		}
	}

	.btn {
		margin-top: 10px;
		font-weight: bold;
	}

	.description {

		ul {
			padding-left: 25px;
			li {
				margin-bottom: 5px;
				@include media-breakpoint-down(sm) {
					display: table;
					margin:0 auto;
				}
			}
		}
	}

	/**
	 *	Begin 'Dark' Theme
	 */
	&.dark {
		background-color: $primary;
		color: #fff;
		.title-container {
			.title {
				color: #fff;
			}
		}
		a:not(.btn),
		h1,
		h2,
		h3,
		h4,
		h5,
		p {
			color: #fff;
			&:before {
				display: none;
			}
		}
		ul li:before {
			background-color: #fff;
		}
	}

	&.angled {
		background-color: $third;
		padding-top:0;
		overflow: hidden;

		.bg-img {height:300px; margin-bottom: 20px;}

		
		@include media-breakpoint-down(sm) {

			.image  {display: none;}
		}

		.content-wrap {
			text-align: left;
			padding:0 40px;
			border:none;
			background-color: $third;
			color:#fff;

			h1,h2,h4,h6 {color:#fff;}
		}

	}

	.content {
		// text-align: center;

	}

	.content-wrap {
		padding:40px 40px 60px;
		border:2px solid $secondary;
	}

	.image {
		margin-top:-30px;
		.img-wrap {
			height:300px;
			margin:0 20px;
		}
		img {
			width: 100%;
			height: auto;
		}
	}	


	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
		&.angled {

			.bg-img {height:400px;}
		}
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		padding: 80px 0;

		&.image-right  {
			.image {
				.img-wrap {
					right:0;
					left:-40px;
				}
			}
			.content-wrap {
				padding:40px 60px 40px 40px;
			}			
		}

		&.angled {

			.bg-img {height:100%; }

			&.image-right {
				&:before {
					left:-20%;
					right:auto;
				}
				.bg-img {right:0; left:50%;}
			}

			.bg-img {display: block; position: absolute; top:0; right:50%; top:0; bottom:0; left:0; background-size: cover; background-position: center center; z-index: 1;}
			
			

			&:before {
				content:"";
				background-color: $third;
				position: absolute;
				top:0;
				bottom:0;
				right:-20%;
				width:85%;
				transform:skew($skew);
				z-index: 5;
			}

			.content-wrap {

				position: relative;
				z-index: 15;
				background-color: transparent;
				padding:0;
			}

		}


		.content {
			text-align: left;
		}
	

		.image { 
			position: relative;
			margin-top:0;

			.img-wrap {
				margin:0;
				height:auto;
				position: absolute;
				top:6%;
				bottom:6%;
				right:-40px;
				left:0;

			}
			
		}

		.content-wrap {
			border:2px solid $secondary;
			padding:40px 40px 40px 60px;
		}

	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		.content-wrap {
			padding:60px 60px 60px 80px;
		}

		&.image-right  {
			.image {
				.img-wrap {
					left:-60px;
				}
			}

			.content-wrap {
				padding:60px 80px 60px 60px;
			}			
		}			



		.image {
			.img-wrap { right:-60px; }
		}		
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

		.content-wrap {
			padding:60px 60px 60px 100px;
		}		

		&.image-right  {

			.content-wrap {
				padding:60px 100px 60px 60px;
			}	

		}		

		&.angled {
			&:before {
				width:80%;
			}
		}
	}
}
