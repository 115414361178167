.numeric-pagination {
	text-align: center;
	width: 100%;
	border-top: 1px solid #e0e0e0;
	margin-top: 30px;
	padding-top: 30px;
	padding-bottom:60px;
	font-family: $hFont;
	a {
		text-decoration: none;
		color:$primary;
		transition: $transition;
		&.prev, &.next {
			color:$primary;
			@include media-breakpoint-down(lg) {
				display: block;
			}
			&:hover {
				color:$grey-med;
			}
		}
		&.prev {
			margin-right: 20px;
			@include media-breakpoint-down(sm) {
				margin-bottom:20px;
			}
			i {
				margin-right: 10px;
			}
		}
		&.next {
			margin-left: 20px;
			@include media-breakpoint-down(sm) {
				margin-top:20px;
			}
			i {
				margin-left: 10px;
			}
		}
	}

	.page-numbers {
		color:$grey-dark;
		font-weight: bold;
		font-size: 16px;
		margin:0 6px;
		i {
			font-size: 10px;
		}
		&.current {
			color: $primary;
		}
		&:hover:not(.current) {
			color:$grey-med;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		a {
			margin-top: 0;
			&.prev {
				float: left;
			}
			&.next {
				float: right;
			}
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {

	}

}