.menus.mobile {
	position: fixed;
	height: calc(100% - #{$header-mobile-height});
	width: 100%;
	z-index: 100;
	display: block;
	background-color: #fff;
	top: $header-mobile-height; // height of mobile header
	transform:translateX(100%);
	transition: $transition;
	overflow-y: auto;
	padding: 10px 20px 80px;
	.menu-open & {
		transform: translateX(0);
	}

	.admin-bar & {
		top: calc(32px + #{$header-mobile-height});
	}

	nav {
		ul {
			@include list();
			li {
				a {
					display: block;
					text-decoration:none;
				}
			}
		}
		&.nav-primary {
			ul {
				li {
					&.menu-item-has-children {
						position: relative;
						&:before {
							content:'';
							position: absolute;
							right: 20px;
							top: 27px;
							transform: translateY(-50%) rotate(90deg);
							@include iconFont;
							content: "\";
							color: $primary;
							font-size: 13px;
							transition: $transition;
						}
						&:hover {
							&:before {
								color: #fff;
							}
						}
						&.active {
							&:before {
								margin-top: -4px;
								transform: rotate(-90deg);
								color:$secondary;
							}
						}
					}
					.sub-menu {
						max-height: 0;
						overflow: hidden;
						transition: $transition;
						li {
							a {
								font-size: 13px;
								padding-left: 30px;
							}
						}
					}
					a {
						color:$grey-med;
						font-size:20px;
						padding:15px 10px;
						border-bottom:1px solid rgba($grey-med, 0.1);
						transition: $transition;
						&:hover {
							color:#fff;
							background-color:$primary;
							border-bottom:1px solid transparent;
						}
					}
				}
			}
		}
		&.nav-utility {
			li {
				&.menu-emergency-services {
					margin: 20px 0;
					a {
						font-size: 17px;
						padding-top: 20px;
						padding-bottom: 20px;
						background-color: $primary;
						color:#ffffff;
						&:hover {
							color:$secondary;
							background-color:rgba(0,0,0,0.1);
						}
					}
				}
				&[class^='utility-'] {
					span {
						display: none;
					}
					a {
						text-decoration: none;
						color:$primary;
						font-size:16px;
						font-weight: 500;
						margin:20px 0;
						&:before {
							@include iconFont;
							color:$primary;
							vertical-align: -2px;
							margin-right: 10px;
						}
					}
				}
				&.utility-email {
					a {
						&:before {
							content: "\E012";
						}
					}
				}
				&.utility-phone {
					a {
						&:before {
							content: "\E011";
						}
					}
				}
			}
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		display: none;
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
	
}
