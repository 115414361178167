.wrap {

	.block-section {

		padding:10px 0;
		margin:20px 0;

	}

	@include media-breakpoint-up(sm) {
	
	}
	@include media-breakpoint-up(md) {

	.block-section {

		padding:30px 0;
		margin:20px 0;

	}		
	
	}
	@include media-breakpoint-up(lg) {
	
	}
	@include media-breakpoint-up(xl) {
	
	}
}