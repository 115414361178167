.post-item {
	
	margin-bottom:50px;

	img {
		max-width: 100%;
		height: auto;
	}

	.category-list {
		margin-top:10px;
	}

	h5 {
		@include media-breakpoint-down(sm) {
			font-size:20px;
		}

		.wrap & {
			margin-bottom:20px;
			margin-top:0;	
		}
	}
	h5 a {
		color: $grey-dark;
		text-decoration: none;
	}

	.description {
		line-height: 1.93;
		font-weight: 500;
	}

	.read-more {
		margin-top: 20px;
		text-decoration: none;
		color:$primary;
		font-weight: 600;
		display: inline-block;
		font-family: $hFont;
		font-size: 18px;
		transition: $transition;
		&:hover {
			color:$grey-dark;
		}
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		margin-bottom:20px;

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}