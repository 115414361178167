
@font-face {
  font-family: "iconFont";
  src:  url('../fonts/icon/iconFont.woff2?1534203939') format('woff2'),
        url('../fonts/icon/iconFont.woff?1534203939') format('woff');
  font-weight: normal;
  font-style: normal;
}


@mixin iconFont {

  font-family: 'iconFont';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}





[class^="icon-"], [class*=" icon-"] {
  
  &:before {@include iconFont;}

}



.icon-plus:before {
  content: "\"
}
.icon-crew:before {
  content: "\"
}
.icon-instagram:before {
  content: "\"
}
.icon-facebook:before {
  content: "\"
}
.icon-minus:before {
  content: "\"
}
.icon-cross:before {
  content: "\"
}
.icon-chevron-small-up:before {
  content: "\"
}
.icon-chevron-small-right:before {
  content: "\"
}
.icon-chevron-small-left:before {
  content: "\"
}
.icon-chevron-small-down:before {
  content: "\"
}
.icon-phone:before {
  content: "\"
}
.icon-envelope:before {
  content: "\"
}
.icon-linkedin:before {
  content: "\"
}
.icon-twitter:before {
  content: "\"
}