footer.content-info {
	background-color: $third;
	color:#fff;
	padding:50px 0;
	text-align: center;

	img {
		max-width: 200px;
	}

	.nav {
		@include list();
		padding:20px 0;

		a {
			margin-bottom:15px;
			display: block;
			font-size: 16px;
			text-decoration: none;
			text-transform: uppercase;
			line-height: 1.8em;
			@include hFontNormal;
			color:$grey-light;
			transition:$transition;
			&:hover {
				color: $secondary;
			}
		}
		li {
			ul.sub-menu {
				@include list();
				margin-bottom: 20px;
				li {
					a {
						text-transform: none;
						font-weight: normal;
					}
				}
			}
		}
	}

	.parent-wrap {
		.img {
			margin-bottom: 10px;
			width: 50px;
			margin:0 auto;

			img {width:100%; display: block;}
	
		}

		p {margin:5px 0 0; font-size:12px; color:#e4c9b0;}

	}



	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		padding:30px 0;

		.nav { text-align: right; padding-top:0;
			li {display: inline-block; margin-left:25px;

				&:first-child {margin-left:0;}

			}
		}

		.logo-wrap {text-align: left; max-width: 150px;

			img {width:100%;}

		}

		.parent-wrap { text-align: right;
			.img {		
				display: inline-block;
			}
		}


	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}