.single {

	header.article-header {
		background: {
			size:cover;
			position: center;
			repeat:no-repeat;
		}
		padding:4vw 0;
		@include overlay(#000,0.3);
		h1.entry-title {
			color:#fff;
			margin-bottom:20px;
		}
		.category-list li {
			color:#fff;
			font-family: $hFont;
			font-size: 16px;
		}
	}

	.article-body {
		padding:5vw 0;
	}

	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		h1 {
			font-size:44px!important;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}