.menu-open {
	overflow: hidden;
}

body:not(.wp-admin), body:not(tinymce) {
	font-family: $pFont;
}

.wrap {

	hr {
		border-color:$secondary;
	}

	padding-top: $header-mobile-height;

	img {
		max-width: 100%;
		height: auto;
	}

	&:before {
		z-index: 100;
		content:'';
		position:fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		transition-property: opacity, visibility;
		transition-duration: 0.2s, 0s;
		transition-delay: 0s, 0.2s;
		background-color:rgba(255,255,255,0.9);
		opacity: 0;
		visibility: hidden;
		.menu-open & {
			opacity: 1;
			visibility: visible;
			transition-delay: 0s, 0s;
		}
	}

	.alert.alert-warning {
		display: block;
		color:#fff;
		background-color: #de8282;
		border:1px solid #d73030;
		padding:30px;
		width: 100%;
		margin:30px 0;
	}


	.img-cover {
		background-position: center center;
		background-size:cover;
	}

	.img-cover-top {
		background-position: center top;
		background-size:cover;
	}

	.light-grey-bg {
		background-color: $grey-light;
	}
	.primary-bg {
		background-color: $primary;
	}
	.secondary-bg {
		background-color: $secondary;
	}
	.third-bg {
		background-color: $third;
	}		
	.white-bg {
		background-color: #fff;
	}			

	.btn-wrap {
		margin: 20px 0;

		&.nmb {
			margin-bottom: 0;
		}

		&.nmt {
			margin-top: 0;
		}

		&.center {
			text-align: center;
		}

		&.right {
			text-align: right;
		}

		&.left {
			text-align: left;
		}
	}	

	.min-height {
		min-height: 300px;
	}


	.gold-dot {

		position: relative;
		padding-left:12px;
		line-height: 26px;

		&:before {
			content:"";
			display: block;
			border-radius: 50%;
			background-color: $secondary;
			width:6px;
			height: 6px;
			position: absolute;
			top:50%;
			left:0;
			transform:translateY(-50%);
		}
		
	}
	/* =============================================================================
	Extra Small +
	========================================================================== */ 
	@include media-breakpoint-up(xs) {

	} 

	/* =============================================================================
	Small +
	========================================================================== */ 
	@include media-breakpoint-up(sm) {

	}


	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {

		.min-height {
			min-height: 400px;
		}

	} 

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {

		padding-top: calc(#{$header-desktop-height});
	
	} 

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	
	}
}