.wrap {
	.block.advanced-callout {
		position: relative;
		margin-bottom: 0;

		&:before,
		&:after {
			content: "";
			position: absolute;
			left: 0;
			right: 0;
			height: 20px;
			background-color: $grey-light;
			z-index: 20;
		}

		&:before {
			bottom: 100%;
		}

		&:after {
			display: none;
			top: 100%;
		}

		.title-container {
			text-align: center;
			.title {
				margin-top: 0;
				&:before {
					display: none;
				}
			}
		}

		.item {
			margin-bottom: 30px;
		}

		.inner {
			text-decoration: none;
			display: block;
			transition: $transition;
			text-align: center;
			position: relative;
			padding: 30px 40px;
			height: 100%;
			border: 2px solid $secondary;

			h3 {
				transition: $transition;
			}

			&:hover {
				box-shadow: 0 12px 34px 0 rgba(0, 0, 0, 0.06);
				background-color: $secondary;

				h3 {
					color: $hColor;
				}
				.btn.btn-stripped {
					color: #fff;
					&:after {
						right: -9px;
					}
				}
			}

			.content-wrap {
				max-width: 400px;
				margin: 0 auto;
			}

			.callout-icon {
				background: {
					repeat: no-repeat;
					position: center;
					size: contain;
				}
				width: 50px;
				height: 50px;
				margin: 0 auto 30px;
			}

			.description {
				color: $grey-dark;
				line-height: 24px;
				font-weight: 500;
			}
		}

		.view-all-link {
			text-align: center;
			margin-top: 30px;
		}

		/* =============================================================================
		Extra Small +
		========================================================================== */
		@include media-breakpoint-up(xs) {
		}

		/* =============================================================================
		Small +
		========================================================================== */
		@include media-breakpoint-up(sm) {
		}

		/* =============================================================================
		Medium +
		========================================================================== */
		@include media-breakpoint-up(md) {
			margin-bottom: 0;

			.inner {
				.content-wrap {
					margin-bottom: 70px;
				}

				.btn-wrap {
					margin: 0;
					position: absolute;
					bottom: 30px;
					left: 0;
					right: 0;
				}
			}
		}

		/* =============================================================================
		Large +
		========================================================================== */
		@include media-breakpoint-up(lg) {
		}

		/* =============================================================================
		Extra Large +
		========================================================================== */
		@include media-breakpoint-up(xl) {
		}
	}
}
