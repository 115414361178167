.wrap {
	@include pFont;
	color: $pColor;
	font-size: 16px;
	font-weight: normal;
	h1,.h1,
	h2, .h2,
	h3, .h3,
	h4, .h4,
	h5, .h5,
	h6, .h6 {

		@include hFont;
		margin: 0.8em 0;
		color:$third;
	}
	a {
		color: $primary;
		transition: $transition;
		text-decoration: underline;
		&:hover {
			color: $secondary;
		}
	}



	.main {


			h1, .h1 {
				font-size: 35px;
				letter-spacing: 4px;
			}
			h2, .h2 {
				font-size: 30px;
				letter-spacing: 6px;
			}
			h3, .h3 {
				font-size: 26px;
				letter-spacing: 3px;
				color:$secondary;
			}
			h4, .h4 {
				font-size: 22px;
				letter-spacing: 2px;
			}
			h5, .h5 {
				font-size: 18px;
				text-transform: none;
				color:$secondary;	
			}
			h6, .h6 {
				font-size: 16px;
				text-transform: none;
				@include pFont;
				font-weight: 700;
			}		

		p {
			line-height: 24px;
			margin: 0 0 1em;

		}

		ul,
		ol {
			line-height: 24px;
			li {
			}
		}


		ol {
			counter-reset: section;
		}

		blockquote {
			font-style: italic;
			position: relative;
			background-color: $grey-light;
			font-family: $pFont;
			font-weight: 500;
			line-height: 1.86;
			padding: 20px 20px;
			p {
				line-height: 26px;
			}
			&:before,
			&:after {
				content: '"';
				position: absolute;
				font-size: 72px;
				font-family: $hFont;
			}
			&:before {
				top: -10px;
				left: -20px;
			}
			&:after {
				bottom: -40px;
				right: 0px;
			}
		}

		.primary {color:$primary;}
		.secondary {color:$secondary;}
		.third {color:$third;}

	}

	/* =============================================================================
	Extra Small +
	========================================================================== */
	@include media-breakpoint-up(xs) {
	}

	/* =============================================================================
	Small +
	========================================================================== */
	@include media-breakpoint-up(sm) {
	}

	/* =============================================================================
	Medium +
	========================================================================== */
	@include media-breakpoint-up(md) {
		.main {

			p {
				&.larger {
					font-size: 20px;
					line-height: 30px;
				}
			}
			.larger {
				font-size: 20px;
				line-height: 30px;
			}			
			h1, .h1 {
				font-size: 62px;
			}
			h2, .h2 {
				font-size: 42px;
			}
			h3, .h3 {
				font-size: 32px;
			}
			h4, .h4 {
				font-size: 22px;
			}
			h5, .h5 {
				font-size: 20px;
			}
			h6, .h6 {
				font-size: 18px;
			}
		}
	}

	/* =============================================================================
	Large +
	========================================================================== */
	@include media-breakpoint-up(lg) {
	}

	/* =============================================================================
	Extra Large +
	========================================================================== */
	@include media-breakpoint-up(xl) {
	}
}
